import {init as initNavigationButton} from './components/navigation-button';
import {init as initBackdrop} from './components/backdrop';
import {init as initGameTicker} from './components/game-ticker';
import { initSwiper } from './modules/swiper';

// TODO: Import tailwind.config.js directly
const tailwindConfig = {
	theme: {
		screens: {
			'sm': '640px',
			'md': '768px',
			'lg': '1024px',
			'xl': '1280px'
		},
		extend: {
			colors: {
				downriver: '#160033',
				midnightBlue: '#291641',
				crimson: '#FF6600'
			}
		}
	}
};

// DOM has been loaded
document.addEventListener('DOMContentLoaded', function () {
	initNavigationButton();
	initBackdrop(tailwindConfig.theme.screens.md);
	initGameTicker(tailwindConfig.theme.screens.lg);
	initSwiper();
});

// Complete page has been loaded
window.addEventListener('load', function () {
});
