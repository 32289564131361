import { Swiper, Autoplay } from 'swiper'

export function initSwiper() {
  new Swiper('[data-swiper="sponsors"]', {
    slidesPerView: 1,
    modules: [Autoplay],
    autoplay: {
      delay: 3000,
    },
    grabCursor: true,
    speed: 400,
    loop: true,
  })
}
