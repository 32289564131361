export function getJSON(url: string): Promise<Object> {
	let request: XMLHttpRequest = new XMLHttpRequest();

	request.open('GET', url, true);

	return new Promise(function (resolve, reject): void {

		request.onload = function(): void {
			if (this.status >= 200 && this.status < 400) {
				// Success!
				resolve(JSON.parse(this.response));
			} else {
				// We reached our target server, but it returned an error
				reject();
			}
		};

		request.onerror = function(): void {
			// There was a connection error of some sort
			reject();
		};

		request.send();
	});
}