import {getJSON} from '../utils/ajax-service';
import moment, {Moment} from 'moment';
import 'moment/locale/de';

// set german locale
moment.locale('de');

export function init(breakpoint: string): void {
	initTicker('[data-game-ticker="first"]', '/_data/season/first.json', breakpoint);
	initTicker('[data-game-ticker="second"]', '/_data/season/second.json', breakpoint);
}

function initTicker(selector: string, jsonURL: string, breakpoint: string): void {
  const gameTicker: HTMLDivElement = document.querySelector(selector) as HTMLDivElement;
  const gameTickerOpponent: HTMLSpanElement = gameTicker.querySelector('#game-ticker-opponent') as HTMLSpanElement;
  const gameTickerTipOff: HTMLSpanElement = gameTicker.querySelector('#game-ticker-tip-off') as HTMLSpanElement;

  if (
    window.matchMedia(`screen and (min-width: ${breakpoint})`).matches &&
    gameTicker &&
    gameTickerOpponent &&
    gameTickerTipOff
  ) {
    getJSON(jsonURL).then((res: Object): void => {
      setNextGame(res, gameTicker, gameTickerOpponent, gameTickerTipOff);
      handleWindowResize(breakpoint, gameTicker);
    }, (): void => {
      console.error('ERROR: Could not get JSON for game-ticker: ' + jsonURL);
    });
  }
}

function setNextGame(
  res: any,
  gameTicker: HTMLDivElement,
  gameTickerOpponent: HTMLSpanElement,
  gameTickerTipOff: HTMLSpanElement
): void {
	const currentDate: Moment = moment();

	let tipOffTime: Moment | null = null;
	let opponent: string = '';

	for (let i: number = 0; i < res.games.length; i++) {
		tipOffTime = moment(res.games[i].tipOff);

		// skip away games
		if (!res.games[i].home) continue;

		// skip games in the past
		if (tipOffTime.isBefore(currentDate)) continue;

		// set opponent and stop looping
		opponent = res.games[i].opponent;
		break;
	}

	if (opponent !== '' && gameTicker && gameTickerOpponent && tipOffTime && gameTickerTipOff) {
		gameTickerOpponent.textContent = opponent;
		gameTickerTipOff.textContent = `${tipOffTime.format('L')} um ${tipOffTime.format('LT')}`;
		gameTicker.style.visibility = 'visible';
		gameTicker.classList.add('lg:block')
	}
}

function handleWindowResize(breakpoint: string, gameTicker: HTMLDivElement): void {
	window.addEventListener('resize', (): void => {
		if (!window.matchMedia(`screen and (min-width: ${breakpoint})`).matches) {
			if (gameTicker) {
				gameTicker.style.visibility = 'invisible';
			}
		}
	});
}