export function init() {
	const bodyElement: HTMLBodyElement | null = document.querySelector('body');
	const buttonElement: HTMLButtonElement | null = document.querySelector('.navigation-button');

	if (bodyElement && buttonElement) {
		buttonElement.addEventListener('click', () => {
			bodyElement.classList.toggle('nav-open');
		});
	}
}
