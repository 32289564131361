let body: HTMLBodyElement | null;
let backdrop: HTMLDivElement | null;

export function init(breakpoint: string) {
	body = document.querySelector('body');
	backdrop = document.querySelector('.backdrop');

	if (body && backdrop) handleBackdropClick(body, backdrop);
	if (body && backdrop) handleWindowResize(body, backdrop, breakpoint);
}

function handleBackdropClick(body: HTMLBodyElement, backdrop: HTMLDivElement) {
	backdrop.addEventListener('click', () => {
		body.classList.toggle('nav-open');
	});
}

function handleWindowResize(body: HTMLBodyElement, backdrop: HTMLDivElement, breakpoint: string) {
	window.addEventListener('resize', () => {
		if (window.matchMedia(`screen and (min-width: ${breakpoint})`).matches) {
			if (body.classList.contains('nav-open')) {
				body.classList.remove('nav-open');
			}
		}
	});
}
